import useWebSocket, { Options } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { devlogger } from '../utils';
import { AuthorizationMessage, WebsocketMessageType } from './chat-types';
import { chatLogger, WebsocketMessage } from './chat-utils';

const websocketDefaultConfigs = {
  share: true,
  // 10 minutes of reconnect retries
  reconnectAttempts: 100,
  reconnectInterval: 6000,
  onMessage: (event: MessageEvent<string>) => {
    devlogger('[WEBSOCKET]: Websocket message received', event);
  },
};

function useDuodecimWebsocket(
  accessToken: string | null,
  options?: Options | undefined,
): WebSocketHook<unknown, MessageEvent<WebsocketMessage> | null> {
  const url = `${process.env.CHAT_WEBSOCKET_URL}/Chat`;

  const { sendMessage, readyState, ...restWebsocketData } = useWebSocket(
    accessToken ? url : null,
    {
      ...websocketDefaultConfigs,
      shouldReconnect: (closeEvent) => {
        devlogger(
          `[Chat]: should ${
            accessToken ? '' : 'NOT'
          } reconnect - Websocket close event:`,
          closeEvent,
          accessToken,
        );
        return !!accessToken;
      },
      onOpen(event: Event) {
        if (!accessToken) return;
        chatLogger('opOpen event', event);
        const authMessage: AuthorizationMessage = {
          messageType: WebsocketMessageType.AuthorizationMessage,
          timeStamp: new Date().toISOString(),
          authorizationToken: accessToken,
        };
        chatLogger(`[Chat]: Websocket onOpen callback`, authMessage);
        sendMessage(JSON.stringify(authMessage));
      },
      ...options,
    },
  );
  return { ...restWebsocketData, readyState, sendMessage };
}

export { useDuodecimWebsocket };
